// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { HashButton } from "../../../../components/AccountAddressButton";
import { ClearButton } from "./ClearButton";

interface TargetAddressProps {
  domainName: string;
  isOwner: boolean;
  targetAddress: string | undefined;
}

export function TargetAddress({ targetAddress, domainName, isOwner }: TargetAddressProps) {
  const [address, setAddress] = useState<string | undefined>(undefined);

  useEffect(() => {
    setAddress(targetAddress);
  }, [targetAddress]);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="column" spacing={2}>
          <Typography noWrap variant="h6" fontWeight="bold">
            Target Address
          </Typography>
          {address && <HashButton address={address} />}
        </Stack>
        {address && (
          <ClearButton domainName={domainName} disable={!isOwner} callback={setAddress} />
        )}
      </Stack>
    </Box>
  );
}
