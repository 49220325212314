import * as Types from './operations';

import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export const Ans_Record_LookupFragmentDoc = gql`
    fragment ANS_RECORD_LOOKUP on current_ans_lookup_v2 {
  domain
  expiration_timestamp
  registered_address
  subdomain
  token_standard
}
    `;
export const Ans_RecordFragmentDoc = gql`
    fragment ANS_RECORD on current_aptos_names {
  domain
  expiration_timestamp
  subdomain_expiration_policy
  domain_expiration_timestamp
  registered_address
  subdomain
  token_standard
  is_primary
  owner_address
}
    `;
export const Domain_Availability_SearchDocument = gql`
    query domain_availability_search($domain: String, $expiration_timestamp: timestamp) {
  current_ans_lookup_v2(
    where: {domain: {_eq: $domain}, subdomain: {_eq: ""}, _or: {expiration_timestamp: {_gte: $expiration_timestamp}}}
  ) {
    ...ANS_RECORD_LOOKUP
  }
  current_aptos_names(
    limit: 1
    where: {domain: {_eq: $domain}}
    order_by: {expiration_timestamp: asc}
  ) {
    ...ANS_RECORD
  }
}
    ${Ans_Record_LookupFragmentDoc}
${Ans_RecordFragmentDoc}`;
export const Get_DomainDocument = gql`
    query get_domain($owner_address: String, $domain: String, $subdomain: String) {
  current_aptos_names(
    limit: 1
    where: {owner_address: {_eq: $owner_address}, subdomain: {_eq: $subdomain}, domain: {_eq: $domain}}
    order_by: {expiration_timestamp: asc}
  ) {
    ...ANS_RECORD
  }
}
    ${Ans_RecordFragmentDoc}`;
export const Get_DomainsDocument = gql`
    query get_domains($owner_address: String, $expiration_lte: timestamp, $expiration_gte: timestamp, $token_standard: [String!], $offset: Int, $limit: Int) {
  current_aptos_names(
    limit: $limit
    where: {owner_address: {_eq: $owner_address}, subdomain: {_eq: ""}, expiration_timestamp: {_gte: $expiration_gte, _lte: $expiration_lte}, token_standard: {_in: $token_standard}}
    order_by: [{is_primary: desc}, {expiration_timestamp: asc}]
    offset: $offset
  ) {
    ...ANS_RECORD
  }
}
    ${Ans_RecordFragmentDoc}`;
export const Get_Domains_CountDocument = gql`
    query get_domains_count($owner_address: String, $expiration_lte: timestamp, $expiration_gte: timestamp, $token_standard: [String!]) {
  current_aptos_names_aggregate(
    where: {owner_address: {_eq: $owner_address}, subdomain: {_eq: ""}, expiration_timestamp: {_gte: $expiration_gte, _lte: $expiration_lte}, token_standard: {_in: $token_standard}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Get_Account_Orphaned_SubdomainsDocument = gql`
    query get_account_orphaned_subdomains($owner_address: String, $offset: Int, $limit: Int) {
  current_aptos_names(
    limit: $limit
    where: {owner_address: {_eq: $owner_address}, subdomain: {_neq: ""}, _not: {is_domain_owner: {}}}
    order_by: [{is_primary: desc}]
    offset: $offset
  ) {
    ...ANS_RECORD
  }
}
    ${Ans_RecordFragmentDoc}`;
export const Get_Account_Orphaned_Subdomains_CountDocument = gql`
    query get_account_orphaned_subdomains_count($owner_address: String) {
  current_aptos_names_aggregate(
    where: {owner_address: {_eq: $owner_address}, subdomain: {_neq: ""}, _not: {is_domain_owner: {}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Get_Domain_SubdomainsDocument = gql`
    query get_domain_subdomains($domain: String, $offset: Int, $burn_address: String, $limit: Int) {
  current_aptos_names(
    where: {subdomain: {_neq: ""}, owner_address: {_neq: $burn_address}, domain: {_eq: $domain}}
    limit: $limit
    offset: $offset
  ) {
    ...ANS_RECORD
  }
}
    ${Ans_RecordFragmentDoc}`;
export const Get_Domain_Subdomains_CountDocument = gql`
    query get_domain_subdomains_count($domain: String, $burn_address: String) {
  current_aptos_names_aggregate(
    where: {subdomain: {_neq: ""}, owner_address: {_neq: $burn_address}, domain: {_eq: $domain}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    domain_availability_search(variables?: Types.Domain_Availability_SearchQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Domain_Availability_SearchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Domain_Availability_SearchQuery>(Domain_Availability_SearchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'domain_availability_search', 'query', variables);
    },
    get_domain(variables?: Types.Get_DomainQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Get_DomainQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Get_DomainQuery>(Get_DomainDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_domain', 'query', variables);
    },
    get_domains(variables?: Types.Get_DomainsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Get_DomainsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Get_DomainsQuery>(Get_DomainsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_domains', 'query', variables);
    },
    get_domains_count(variables?: Types.Get_Domains_CountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Get_Domains_CountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Get_Domains_CountQuery>(Get_Domains_CountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_domains_count', 'query', variables);
    },
    get_account_orphaned_subdomains(variables?: Types.Get_Account_Orphaned_SubdomainsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Get_Account_Orphaned_SubdomainsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Get_Account_Orphaned_SubdomainsQuery>(Get_Account_Orphaned_SubdomainsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_account_orphaned_subdomains', 'query', variables);
    },
    get_account_orphaned_subdomains_count(variables?: Types.Get_Account_Orphaned_Subdomains_CountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Get_Account_Orphaned_Subdomains_CountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Get_Account_Orphaned_Subdomains_CountQuery>(Get_Account_Orphaned_Subdomains_CountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_account_orphaned_subdomains_count', 'query', variables);
    },
    get_domain_subdomains(variables?: Types.Get_Domain_SubdomainsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Get_Domain_SubdomainsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Get_Domain_SubdomainsQuery>(Get_Domain_SubdomainsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_domain_subdomains', 'query', variables);
    },
    get_domain_subdomains_count(variables?: Types.Get_Domain_Subdomains_CountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.Get_Domain_Subdomains_CountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.Get_Domain_Subdomains_CountQuery>(Get_Domain_Subdomains_CountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'get_domain_subdomains_count', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;