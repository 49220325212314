// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { LoadingModal } from "../../components/LoadingModal";
import { useDomainSearch } from "../../context/hooks/useDomainSearch";
import { extractAptFromDomainName, isValidDomainName } from "../../utils";
import { DomainNameInfo } from "./_components/DomainNameInfo";
import { DomainNameRegister } from "./_components/DomainNameRegister";
import { InvalidDomainName } from "./_components/InvalidDomainName";

interface DomainSearchPageURLParams {
  domain: string;
}

export function DomainSearchPage(): JSX.Element {
  const { domain } = useParams() as unknown as DomainSearchPageURLParams;
  const domainName = extractAptFromDomainName(domain);
  const { domain: domainRes, isOwner, error, loading } = useDomainSearch(domainName);

  if (!isValidDomainName(domainName)) {
    return <InvalidDomainName domain={domain} />;
  }

  if (loading) {
    return <LoadingModal open={loading} />;
  }

  if (error) {
    return <Typography>Something went wrong</Typography>;
  }

  if (!domainRes) {
    return <DomainNameRegister />;
  }

  return <DomainNameInfo domain={domainRes} isOwner={Boolean(isOwner)} />;
}
