// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useQuery } from "@tanstack/react-query";

import { getExpirationKeyDates } from "../../utils/getExpirationStatus";
import { indexer } from "../../utils/indexer";
import { parseAptosNamesSearch, parseCurrentAptosName } from "../../utils/parseIndexerNames";
import { CurrentAptosName } from "../../utils/types";

const expiration_timestamp = getExpirationKeyDates("expired");

export function useDomainSearch(domainName: string) {
  const { account } = useWallet();
  const accountAddress = account?.address;

  const { data, isLoading, error } = useQuery({
    queryKey: ["domain-search", domainName, accountAddress],
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const res = await indexer.domain_availability_search({
          domain: domainName,
          expiration_timestamp: expiration_timestamp.toISOString(),
        });

        const searchResult = res.current_ans_lookup_v2[0]
          ? parseAptosNamesSearch(res.current_ans_lookup_v2[0])
          : null;

        const nameResult = res.current_aptos_names[0]
          ? parseCurrentAptosName(res.current_aptos_names[0] as CurrentAptosName)
          : null;

        const isOwner = accountAddress && nameResult?.owner_address === accountAddress;

        return { searchResult, isOwner };
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
  });

  return { domain: data?.searchResult, isOwner: data?.isOwner, loading: isLoading, error };
}
