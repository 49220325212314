// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { WalletConnector } from "@aptos-labs/wallet-adapter-mui-design";
import { Divider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CloseIcon } from "../assets/svg/icon_close.svg";
import { ReactComponent as HamburgerIcon } from "../assets/svg/icon_hamburger.svg";
import { useGlobalState } from "../context";
import { grey } from "../themes/colors/aptosColorPalette";

export function NavMobile() {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const state = useGlobalState();
  const menuOpen = Boolean(menuAnchorEl);

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleCloseAndNavigate = (to: string) => {
    setMenuAnchorEl(null);
    navigate(to);
  };

  return (
    <Box sx={{ display: { xs: "block", md: "none" } }}>
      <Button
        id="nav-mobile-button"
        aria-controls={menuOpen ? "nav-mobile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleIconClick}
        sx={{
          minWidth: "0",
          width: "1.5rem",
          padding: "0",
          ml: 2,
          color: "inherit",
          "&:hover": {
            background: "transparent",
            color: theme.palette.mode === "dark" ? grey[100] : grey[400],
          },
          "&[aria-expanded=true]": { opacity: "0.7" },
        }}
      >
        {menuOpen ? <CloseIcon /> : <HamburgerIcon />}
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "nav-mobile-button",
          sx: {
            padding: "1rem",
          },
        }}
        sx={{
          marginTop: "1rem",
          boxShadow: 0,
          minWidth: "400px",
          maxWidth: "none",
        }}
      >
        <MenuItem
          onClick={() => {
            handleCloseAndNavigate("/account");
          }}
        >
          Name Manager
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseAndNavigate("/about/documentation");
          }}
        >
          Docs
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseAndNavigate("/about/faq");
          }}
        >
          FAQ
        </MenuItem>
        <Divider />
        <WalletConnector
          networkSupport={state.networkSupport}
          handleNavigate={() => {
            navigate("/account");
          }}
          // Ensure that Petra is always at the top of the wallet list
          sortAvailableWallets={(a) => (a.name === "Petra" ? -1 : 1)}
          sortInstallableWallets={(a) => (a.name === "Petra" ? -1 : 1)}
        />
      </Menu>
    </Box>
  );
}
